import React from 'react'
import { Link } from 'react-router-dom'
import SectionTitle2 from '../SectionTitle2';


const Pricing = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }


    const pricing = [
        {
            rate: '49',
            des: 'Notre service de conception en plans 2D et 3D est idéal pour visualiser et optimiser votre espace avant de commencer les travaux.',
            li1: 'Métrage : Nous mesurons précisément votre espace pour assurer une planification exacte',
            li2: 'Conseil en Optimisation d’Espace : Bénéficiez de nos recommandations pour tirer le meilleur parti de votre espace en lien avec vos travaux',
            li3: 'Estimation Budgétaire : Recevez une estimation initiale des coûts de vos travaux pour une meilleure planification financière',
            title: 'Plan 2D/3D',
            link: '/contact',
        },
        {
            rate: '79',
            des: 'Cette prestation est conçue pour ceux qui envisagent d’investir dans un bien immobilier et souhaitent une évaluation professionnelle.',
            li1: 'Visite du Bien : Nous examinons le bien en personne pour une évaluation complète',
            li2: 'Étude Budgétaire Précise : Vous recevrez une étude détaillée des coûts sous 48 heures',
            li3: 'Conseils en Optimisation de Matériaux : Nos experts vous conseilleront sur les meilleurs matériaux pour votre projet, en alliant qualité et coût-efficacité',
            title: 'Étude Projet Immobilier',
            link: '/contact',
        },
        {
            rate: '249',
            des: 'Notre service d’accompagnement en Assistance à Maîtrise d’Ouvrage (AMOA) vous assure une gestion et un suivi de projet sans faille.',
            li1: 'Comparatif des Devis : Nous vous aidons à analyser et à choisir les meilleurs devis',
            li2: 'Réunion Hebdomadaire : Restez informé avec des mises à jour régulières et des réunions de suivi',
            li3: 'Suivi de Chantier : Nous supervisons les travaux pour garantir qu’ils se déroulent conformément au plan établi',
            title: 'Accompagnement AMOA',
            link: '/contact',
        },


    ]


    return (
        <section className={`wpo-pricing-section section-padding ${props.pClass}`}>
            <div className="container">
                <SectionTitle2 subTitle={'Nos offres tarifaires'} vText={'Offres'} />
                <div className="wpo-pricing-wrap">
                    <div className="row">
                        {pricing.map((pricing, ptem) => (
                            <div className="col col-lg-4 col-md-6 col-12" key={ptem}>
                                <div className="wpo-pricing-item">
                                    <div className="wpo-pricing-top">
                                        <div className="pricing-thumb">
                                            <span>{pricing.title}</span>
                                        </div>
                                        <div className="wpo-pricing-text">
                                        <h2><span>à partir de /&nbsp;</span>{pricing.rate}€ HT</h2>
                                            <p>{pricing.des}</p>
                                        </div>
                                    </div>
                                    <div className="wpo-pricing-bottom">
                                        <div className="wpo-pricing-bottom-text">
                                            <ul>
                                                <li>{pricing.li1}</li>
                                                <li>{pricing.li2}</li>
                                                <li>{pricing.li3}</li>
                                                <li>{pricing.li4}</li>
                                                <li>{pricing.li5}</li>
                                            </ul>
                                            <Link onClick={ClickHandler} to={pricing.link}>Choisir ce plan</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="invisible-title1">
                <h2>Pricing</h2>
            </div>
        </section>
    )
}

export default Pricing;