import React from 'react'
import { Link } from 'react-router-dom'
import VideoModal from '../../components/ModalVideo'
import abimg from '../../images/about5.jpg'
import abimg2 from '../../images/about-shape3.png'


const About = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    return (

        <div className="wpo-about-area-s4 section-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="wpo-about-img">
                            <img src={abimg} alt="" />
                            <div className="wpo-about-img-text">
                                <h2>10+</h2>
                                <p>Années d'Expérience</p>
                                <div className="about-shape">
                                    <img src={abimg2} alt="" />
                                </div>
                            </div>
                            <div className="left-shape">
                                <div className="square-shape"></div>
                                <div className="shape-top">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <div className="shape-left">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 colsm-12">
                        <div className="wpo-about-text">
                            <div className="wpo-about-title">
                                <span>Qui sommes-nous ?</span>
                                <h2>Imaginez, nous créons</h2>
                            </div>
                            <h5>Embarquez dans l'aventure Carib'n Services, où vos projets se concrétisent en harmonie avec le climat caribéen et nos matériaux locaux.</h5>
                            <p>Explorez des solutions de rénovation innovantes et durables adaptées à notre environnement unique. Ensemble, transformons vos idées en espaces réels et inspirants.</p>
                            <div className="btns">
                                <Link to="/a-propos" onClick={ClickHandler} className="theme-btn">En savoir plus</Link>
                                {/*<ul>
                                    <li className="video-holder">
                                        <VideoModal />
                                    </li>
                                    <li className="video-text">
                                        Notre vidéo de présentation
                                    </li>
                                </ul>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="invisible-title1">
                <h2>About</h2>
            </div>
        </div>
    )
}

export default About;