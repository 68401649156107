import React from 'react';

const Conditions = () => {

    return (
        <div className="wpo-project-single-area section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-12">
                        <div className="wpo-project-single-wrap">
                            <div className="wpo-project-single-item">
                                
                                <div className="wpo-project-single-item">
                                    <div className="wpo-project-single-title">
                                        <h3>Introduction</h3>
                                    </div>
									<p className="mb-0">Bienvenue sur le site web de Carib'n Services. En accédant à notre site, vous acceptez de vous conformer à ces termes et conditions d'utilisation, à toutes les lois et réglementations applicables, et acceptez que vous êtes responsable du respect des lois locales applicables.</p>
                                </div>

                                <div className="wpo-project-single-item">
                                    <div className="wpo-project-single-title">
                                        <h3>Utilisation des Contenus</h3>
                                    </div>
									<p className="mb-0">Le contenu du site de Carib'n Services est protégé par le droit d'auteur et les marques déposées. Vous pouvez télécharger une copie des matériaux pour un usage personnel et non commercial, à condition de ne pas modifier ou copier les matériaux, utiliser les matériaux à des fins commerciales ou pour toute exposition publique (commerciale ou non commerciale), tenter de décompiler ou de désassembler tout logiciel contenu sur le site de Carib'n Services.</p>
                                </div>      
                                
                                <div className="wpo-project-single-item">
                                    <div className="wpo-project-single-title">
                                        <h3>Limitation de Responsabilité</h3>
                                    </div>
									<p className="mb-0">Les informations sur le site de Carib'n Services sont fournies "telles quelles". Carib'n Services ne garantit pas l'exactitude, la complétude ou l'utilité de ces informations et ne sera pas responsable des erreurs ou omissions dans les informations ni de tout dommage résultant de leur utilisation.</p>
                                </div>

                                <div className="wpo-project-single-item">
                                    <div className="wpo-project-single-title">
                                        <h3>Modifications des Termes</h3>
                                    </div>
									<p className="mb-0">Carib'n Services peut réviser ces termes et conditions d'utilisation pour son site web à tout moment sans préavis. En utilisant ce site web, vous acceptez d'être lié par la version actuelle de ces termes et conditions d'utilisation.</p>
                                </div>

                                <div className="wpo-project-single-item">
                                    <div className="wpo-project-single-title">
                                        <h3>Liens</h3>
                                    </div>
									<p className="mb-0">Carib'n Services n'a pas examiné tous les sites liés à son site web et n'est pas responsable du contenu de ces sites liés. L'inclusion de tout lien n'implique pas l'approbation du site par Carib'n Services. L'utilisation de tout site web lié est aux risques et périls de l'utilisateur.</p>
                                </div>

                                <div className="wpo-project-single-item">
                                    <div className="wpo-project-single-title">
                                        <h3>Droit Applicable</h3>
                                    </div>
									<p className="mb-0">Toute réclamation relative au site web de Carib'n Services sera régie par les lois de l'État sans égard à ses conflits de dispositions légales.</p>
                                </div>

                                <div className="wpo-project-single-item">
                                    <div className="wpo-project-single-title">
                                        <h3>Politique de Confidentialité</h3>
                                    </div>
									<p className="mb-0">Votre utilisation du site est également régie par notre Politique de Confidentialité. Veuillez consulter notre Politique de Confidentialité.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Conditions;
