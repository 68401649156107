import simg7 from '../images/service-single/1.jpg'
import simg8 from '../images/service-single/2.jpg'
import simg9 from '../images/service-single/3.jpg'


const Services = [
    {
        Id: '1',
        sTitle: 'Réalisation de Plans 2D et 3D',
        description:'Nos compétences en conception vous permettent de visualiser votre projet avant même le début des travaux. Nous transformons vos idées en plans détaillés, facilitant décisions et planifications.',
        icon:'fi flaticon-planning',
    },
    {
        Id: '2',
        sTitle: 'Conseil en Rénovation',
        description:'Notre expertise s’étend au-delà de la construction. Nous offrons une assistance complète en AMOA (Assistance à Maîtrise d’Ouvrage) et AMOE (Assistance à Maîtrise d’Œuvre) pour vous guider à chaque étape.',
        icon:'fi flaticon-double-bed',
    },
    {
        Id: '3',
        sTitle: 'Étude Budgétaire',
        description:'Nous vous accompagnons dans l’élaboration d’une planification financière judicieuse en vous proposant des analyses budgétaires approfondies, parfaitement ajustées à vos exigences et à votre capacité financière.',
        icon:'fi flaticon-blueprint',
    },
    {
        Id: '4',
        sTitle: 'Rénovation Énergétique',
        description:'Nos formations spécialisées nous permettent de vous conseiller sur les meilleures pratiques en matière de rénovation énergétique, pour des bâtiments plus éco-responsables.',
        icon:'fi flaticon-armchair',
    },
    {
        Id: '5',
        sTitle: 'Matériaux Innovants',
        description:'Nous nous engageons à promouvoir la durabilité en proposant des matériaux de rénovation à la fois innovants et respectueux de l’environnement.',
        icon:'fi flaticon-furniture',
    },
    {
        Id: '6',
        sTitle: 'Apport d’Affaires',
        description:'Nous mettons en relation les clients avec les meilleurs artisans et fournisseurs locaux, assurant ainsi la qualité et la fiabilité des travaux effectués.',
        icon:'fi flaticon-interior-design',
    },
    {
        Id: '7',
        sImg:simg7,
        sTitle: 'Bureau',
        description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
    },
    {
        Id: '8',
        sImg:simg8,
        sTitle: 'Salon',
        description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
    },
    {
        Id: '9',
        sImg:simg9,
        sTitle: 'Cuisine',
        description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
    },
]
export default Services;