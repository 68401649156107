import React from 'react';
import ContactForm from '../ContactFrom'
import SectionTitle2 from '../SectionTitle2';


const Contactpage = () => {

    return(
        <section className="wpo-contact-pg-section section-padding">
            <div className="container">
            <SectionTitle2 subTitle={'Nos informations'} vText={'Infos'} />
                <div className="row">
                    <div className="col col-lg-10 offset-lg-1">
                        <div className="office-info">
                            <div className="row">
                                
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-email"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Adresse email</h2>
                                            <p>caribnservices@gmail.com</p>
                                        </div>
                                    </div>
                                </div> 

                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-location"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Localisation</h2>
                                            <p>18 Cour Campeche <br/> 97200, Fort-de-France</p>
                                        </div>
                                    </div>
                                </div> 

                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-telephone"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Téléphone</h2>
                                            <p>+33 6 09 07 65 32</p>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div className="wpo-contact-title">
                            <h2>Vous souhaitez obtenir des renseignements ?</h2>
                            <p>Remplissez le formulaire ci-dessous et nous vous répondrons dans un délai maximal de 48h.</p>
                        </div>
                        <div className="wpo-contact-form-area">
                            <ContactForm/>
                        </div>
                    </div>                
                </div>
            </div> 
            <section className="wpo-contact-map-section">
                <div className="wpo-contact-map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1481.2301391243012!2d-61.06037787183862!3d14.606010989838632!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c63d2a55555c6b7%3A0x660a1ce7d04d3c1a!2s18%20Cour%20Campeche%2C%2097200%20Fort-de-France%2C%20Martinique!5e0!3m2!1sen!2sbd!4v1547528325671"></iframe>
                </div>
            </section>
        </section>
     )
        
}

export default Contactpage;
