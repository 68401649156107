import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";

import Homepage from '../HomePage'
import AboutPage from '../AboutPage'
import ServicePage from '../ServicePage'
import ProjectPage from '../ProjectPage'
import ErrorPage from '../ErrorPage'
import Contactpage from '../ContactPage'
import MentionsLegales from '../MentionsLegales'
import PolitiqueConfidentialite from '../PolitiqueConfidentialite'
import TermesConditions from '../TermesConditions';
import Price from '../Price';

const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Homepage/>} />
          <Route path='a-propos' element={<AboutPage/>} />
          <Route path='nos-services' element={<ServicePage/>} />
          <Route path='nos-realisations' element={<ProjectPage/>} />
          <Route path='*' element={<ErrorPage/>} />
          <Route path='contact' element={<Contactpage/>} />
          <Route path='mentions-legales' element={<MentionsLegales/>} />
          <Route path='politique-de-confidentialite' element={<PolitiqueConfidentialite/>} />
          <Route path='termes-et-conditions' element={<TermesConditions/>} />
          <Route path='tarifs' element={<Price/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default AllRoute;
