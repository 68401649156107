import React, { Fragment } from 'react';
import Scrollbar from '../../components/scrollbar'
import SectionTitle2 from '../SectionTitle2';

const Us = (props) => {

    return (
        <Fragment>
            <div className="wpo-project-single-area section-padding">
                <div className="container">
                <SectionTitle2 subTitle={'Qui sommes-nous ?'} vText={'Nous'} />
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-12">
                            <div className="wpo-project-single-wrap">
                                <div className="wpo-project-single-item">
                                    <div className="wpo-project-single-item list-widget">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="wpo-project-single-title">
                                                    <h3>Notre vision</h3>
                                                </div>
                                                <p>Chez Carib'n Services, nos valeurs sont le moteur de notre réussite et la clé de la confiance que nous bâtissons avec nos clients.</p>
                                                <ul>
                                                    <li><strong>Respect des Engagements</strong> : nous sommes convaincus de l'importance de respecter nos engagements en assurant la ponctualité et en répondant aux attentes de nos clients.</li>
                                                    <li><strong>Transparence</strong> : la clarté et l'intégrité constituent le fondement de nos échanges, favorisant une communication ouverte et fiable.</li>
                                                    <li><strong>Innovation</strong> : nous apportons une perspective novatrice à la rénovation en Martinique en encourageant l'adoption de méthodes et de matériaux innovants.</li>
                                                </ul>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="wpo-project-single-item-quote">
                                                    <p>"Mon engagement est de rendre la rénovation en Martinique accessible à tous en offrant des conseils pratiques. Ainsi, chaque client peut entreprendre son projet avec confiance."</p>
                                                    <span>Gérôme NOGRET - <span>Gérant</span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wpo-project-single-item">
                                        <div className="wpo-project-single-title">
                                            <h3>Notre approche</h3>
                                        </div>
                                        <p>Notre approche se distingue par son caractère novateur et son application pratique. Forts de notre expérience terrain, nous comprenons que chaque projet de rénovation est unique. Nous combinons notre expertise en gestion de projet avec une mise en œuvre concrète des techniques les plus avancées. En mettant l’accent sur la collaboration avec nos clients, nous assurons que chaque projet reflète non seulement leurs besoins, mais aussi leurs aspirations, tout en intégrant des solutions durables et efficaces. Chez Carib'n Services, nous nous engageons à transformer vos espaces avec vision et précision, employant des méthodes qui redéfinissent le potentiel de la rénovation dans la Caraïbe.</p>
                                    </div>
                                    
                                    <div className="wpo-project-single-item list-widget">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="wpo-project-single-title">
                                                    <h3>Objectifs</h3>
                                                </div>
                                                <ul>
                                                    <li>Démocratiser la rénovation immobilière en Martinique par l’éducation et le partage de connaissances.</li>
                                                    <li>Offrir des conseils personnalisés et pratiques pour permettre aux clients de prendre des décisions éclairées.</li>
                                                    <li>Promouvoir l’utilisation de matériaux innovants et écologiques dans tous nos projets de rénovation.</li>
                                                    <li>Étendre notre portée à travers la Caraïbe, en assurant la même qualité de service et d’expertise.</li>
                                                </ul>
                                            </div>
                                            <div className="col-lg-6 list-widget-s">
                                                <div className="wpo-project-single-title">
                                                    <h3>Resultats</h3>
                                                </div>
                                                <ul>
                                                    <li>Plus de 100 projets de rénovation réussis en Martinique avec un taux de satisfaction client de 98%.</li>
                                                    <li>Plus de 40 ateliers personnalisés à l’éducation en rénovation immobilière, permettant aux clients de mener à bien leurs projets.</li>
                                                    <li>Réduction significative de l’empreinte carbone grâce à l’utilisation de matériaux durables et de pratiques éco-responsables.</li>
                                                    <li>Expansion de notre expertise et de nos services dans la Caraïbe, renforçant notre vision d'une rénovation accessible et de qualité.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Scrollbar />
        </Fragment>
    )
};
export default Us;
