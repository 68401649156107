import React from 'react';

const Confidentialite = () => {

    return (
        <div className="wpo-project-single-area section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-12">
                        <div className="wpo-project-single-wrap">
                            <div className="wpo-project-single-item">
                                
                                <div className="wpo-project-single-item">
                                    <div className="wpo-project-single-title">
                                        <h3>Introduction</h3>
                                    </div>
									<p className="mb-0">Carib'n Services respecte la vie privée de ses utilisateurs et s'engage à protéger leurs données personnelles. Cette politique de confidentialité explique comment nous collectons, utilisons, divulguons, et protégeons les informations personnelles des utilisateurs de notre site web.</p>
                                </div>

                                <div className="wpo-project-single-item">
                                    <div className="wpo-project-single-title">
                                        <h3>Collecte d'Informations</h3>
                                    </div>
									<p className="mb-0">Nous collectons des informations lorsque vous utilisez notre site, notamment lors de l'inscription, la prise de contact, l'utilisation de formulaires en ligne, ou la souscription à notre newsletter. Les types d'informations collectées peuvent inclure votre nom, adresse e-mail, numéro de téléphone, et toute autre information que vous choisissez de nous fournir.</p>
                                </div>      
                                
                                <div className="wpo-project-single-item">
                                    <div className="wpo-project-single-title">
                                        <h3>Utilisation des Informations</h3>
                                    </div>
									<p className="mb-0">Les informations collectées sont utilisées pour :</p>
                                    - Répondre à vos demandes et requêtes ;
                                    <br/>
                                    - Améliorer la qualité de nos services et de notre site web ;
                                    <br/>
                                    - Envoyer des communications périodiques, comme des newsletters, si vous avez choisi de les recevoir.
                                    <br/>
                                </div>

                                <div className="wpo-project-single-item">
                                    <div className="wpo-project-single-title">
                                        <h3>Partage des Informations</h3>
                                    </div>
									<p className="mb-0">Carib'n Services ne vend, n'échange, ni ne transfère vos informations personnelles identifiables à des tiers sans votre consentement, sauf dans les cas suivants :
                                    <br/>
                                    - Pour répondre à des exigences légales ou protéger nos droits ;
                                    <br/>
                                    - À des fournisseurs de services tiers qui nous aident à opérer notre site web ou à gérer nos activités, à condition qu'ils acceptent de garder ces informations confidentielles.</p>
                                </div>

                                <div className="wpo-project-single-item">
                                    <div className="wpo-project-single-title">
                                        <h3>Protection de Vos Informations</h3>
                                    </div>
									<p className="mb-0">Nous mettons en œuvre une variété de mesures de sécurité pour maintenir la sécurité de vos informations personnelles lorsque vous entrez, soumettez ou accédez à vos informations personnelles.</p>
                                </div>

                                <div className="wpo-project-single-item">
                                    <div className="wpo-project-single-title">
                                        <h3>Consentement</h3>
                                    </div>
									<p className="mb-0">En utilisant notre site, vous consentez à notre politique de confidentialité.</p>
                                </div>

                                <div className="wpo-project-single-item">
                                    <div className="wpo-project-single-title">
                                        <h3>Modifications de Notre Politique de Confidentialité</h3>
                                    </div>
									<p className="mb-0">Carib'n Services se réserve le droit de modifier cette politique de confidentialité à tout moment. Les modifications seront affichées sur cette page. Nous vous encourageons à consulter régulièrement cette page pour rester informé de la manière dont nous protégeons les informations personnelles que nous collectons.</p>
                                </div>

                                <div className="wpo-project-single-item">
                                    <div className="wpo-project-single-title">
                                        <h3>Contact</h3>
                                    </div>
									<p className="mb-0">Si vous avez des questions concernant cette politique de confidentialité, veuillez nous contacter à l'adresse mail caribnservices@gmail.com, nous vous répondrons le plus rapidement possible.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Confidentialite;
