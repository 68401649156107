import pimg1 from '../images/projects/1.jpg'
import pimg2 from '../images/projects/2.jpg'
import pimg3 from '../images/projects/3.jpg'
import pimg4 from '../images/projects/4.jpg'
import pimg5 from '../images/projects/5.jpg'
import pimg6 from '../images/projects/6.jpg'


const Projects = [
    {
       Id:'1',
       pImg:pimg1,
       title:'Intérieur',  
       subTitle:'Rénovation d’un appartement', 
    },
    {
       Id:'2',
       pImg:pimg2,
       title:'Extérieur',  
       subTitle:'Agencement intégral d’un jardin',   
    },
    {
       Id:'3',
       pImg:pimg3,
       title:'Cuisine', 
       subTitle:'Rénovation d’une cuisine tout équipée',    
    },
    {
      Id:'4',
      pImg:pimg4,
      title:'SDB', 
      subTitle:'Rénovation complète d’une salle de bain',  
   },
    {
       Id:'5',
       pImg:pimg5,
       title:'Bureau', 
       subTitle:'Création d’une extension de bureau',
    },
    {
       Id:'6',
       pImg:pimg6,
       title:'Bungalow',  
      subTitle:'Conception inédite d’un bungalow', 
    },   
]

export default Projects;