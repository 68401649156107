import React, { Component } from "react";
import Slider from "react-slick";
import SectionTitle from "../SectionTitle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ts1 from '../../images/testimonial/img-1.jpg'
import ts2 from '../../images/testimonial/img-2.jpg'
import ts3 from '../../images/testimonial/img-3.jpg'


class Testimonial extends Component {
    render() {
        var settings = {
            dots: false,
            arrows: false,
            speed: 1000,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const testimonial = [
            {
                tsImg: ts1,
                Des: "“J'ai fait appel à Carib'n Services pour une petite rénovation chez moi. J'ai été agréablement surpris par leur approche professionnelle et attentionnée. Ils ont vraiment écouté mes besoins et ont proposé des solutions pratiques et abordables. Leur travail est de bonne qualité et a été réalisé dans les délais. Un grand merci à toute l'équipe !”",
                Title: 'Lenny Carel',
                Sub: "Particulier",
            },
            {
                tsImg: ts2,
                Des: "“En tant que propriétaire d'une petite entreprise, j'étais un peu anxieux à l'idée de me lancer dans la rénovation de nos locaux. Carib'n Services m'a vraiment rassuré. Ils ont géré le projet avec compétence, en respectant mon budget serré. Leur conseil en matière d'efficacité énergétique a été un plus. Je suis satisfait du résultat et recommanderais leurs services à d'autres.”",
                Title: 'Kory Nezelian',
                Sub: "CEO chez Le Cèdre",
            },
            {
                tsImg: ts3,
                Des: "Carib'n Services m'a aidé à rénover l'intégralité de mon appartement. J'ai trouvé leur équipe très réactive et facile à contacter. Leurs suggestions étaient judicieuses et ont bien amélioré l'espace sans que cela me coûte une fortune. Leur sens du détail a fait toute la différence et je suis très content du résultat. Je souhaite les remercie pour leur travail.”",
                Title: 'Matthieu Chalonec',
                Sub: "CEO chez VIRTUAVA",
            }
        ]
        return (
            <div className="wpo-testimonial-area section-padding">
                <div className="container">
                    <SectionTitle subTitle={'Nos avis'} MainTitle={'Ils parlent de nous'}/>
                    <div className="wpo-testimonial-wrap">
                        <div className="testimonial-slider owl-carousel">
                            <Slider {...settings}>
                                {testimonial.map((tesmnl, tsm) => (
                                    <div className="wpo-testimonial-item" key={tsm}>
                                        <div className="wpo-testimonial-top">
                                            <div className="wpo-testimonial-img">
                                                <img src={tesmnl.tsImg} alt="" />
                                            </div>
                                            <div className="wpo-testimonial-info">
                                                <h2>{tesmnl.Title}</h2>
                                                <span>{tesmnl.Sub}</span>
                                            </div>
                                        </div>
                                        <div className="wpo-testimonial-content">
                                            <p>{tesmnl.Des}</p>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Testimonial;