import React from 'react'
import {Link}  from 'react-router-dom'
import Logo from '../../images/logo-blanc.png'
import Projects from '../../api/project'


const Footer = (props) =>{

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

     const currentYear = new Date().getFullYear();

  return(
    <footer className={`wpo-site-footer ${props.ftClass}`}>
        <div className="wpo-upper-footer">
            <div className="container">
                <div className="row">
                    <div className="col col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="widget about-widget">
                            <div className="logo widget-title" style={{ width: '200px', height: 'auto' }}>
                                <Link onClick={ClickHandler} className="logo" to="/"><img src={Logo} alt=""/></Link>
                            </div>
                            <p>Harmonisons ensemble votre environnement en transformant vos idées en espaces réels et inspirants.</p>
                            <ul>
                                <li>
                                    <a href="https://wa.me/message/KSPOK4ACS5MSD1" rel="noreferrer" target="_blank">
                                        <i className="fa fa-whatsapp" style={{ fontSize: '24px' }}></i>
                                    </a>
                                </li>

                                <li>
                                    <a href="https://instagram.com/caribnservices" rel="noreferrer" target="_blank">
                                        <i className="fa fa-instagram" style={{ fontSize: '24px' }}></i>
                                    </a>
                                </li>
                                
                                {/* <li>
                                    <a href="https://facebook.com/" rel="noreferrer" target="_blank">
                                        <i className="fa fa-facebook-square" style={{ fontSize: '24px' }}></i>
                                    </a>
                                </li> */}
                            
                            </ul>
                        </div>
                    </div>
                    
                    <div className="col col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="widget link-widget">
                            <div className="widget-title">
                                <h3>Nos Produits</h3>
                            </div>
                            <ul>
                                <li><Link onClick={ClickHandler} to="/contact">Contact</Link></li>
                                <li><Link onClick={ClickHandler} to="/a-propos">À propos</Link></li>
                                <li><Link onClick={ClickHandler} to="/nos-services">Nos services</Link></li>
                                <li><Link onClick={ClickHandler} to="/nos-réalisations">Nos réalisations</Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="widget wpo-service-link-widget">
                            <div className="widget-title">
                                <h3>Contact </h3>
                            </div>
                            <div className="contact-ft">
                                <ul>
                                    <li><i className="fi flaticon-telephone"></i>+33 6 09 07 65 32</li>
                                    <li><i className="fi flaticon-email"></i>caribnservices@gmail.com</li>
                                    <li><i className="fi flaticon-location"></i>18 Cour Campeche <br/> 97200, Fort-de-France</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="widget instagram">
                            <div className="widget-title">
                                <h3>Gallerie photo</h3>
                            </div>
                            <ul className="d-flex">
                                {Projects.slice(0, 6).map((project, pot) => (
                                    <li key={pot}><Link onClick={ClickHandler} to="/nos-realisations"><img src={project.pImg} alt="gallerie" /></Link></li>
                                ))}
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div className="wpo-lower-footer">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <ul>
                            <li>Copyright &copy; {currentYear} Design & Developed by{' '}
                                <a href="https://virtuava.com" rel="noreferrer" target="_blank" style={{ color: '#5271FF' }}>
                                    <strong>Virtuava</strong>
                                </a>
                            </li>
                            
                            <li><Link onClick={ClickHandler} to="/mentions-legales">Mentions Légales |</Link> <Link onClick={ClickHandler} to="/termes-et-conditions">Termes & Conditions |</Link> <Link onClick={ClickHandler} to="/politique-de-confidentialite">Politique de Confidentialité</Link></li>
                            
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
} 

export default Footer;