import React from 'react'
import Services from '../../api/service'
import SectionTitle3 from '../SectionTitle3'


const Competences = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }


    return (
        <div className={`wpo-service-area-s3 section-padding ${props.ptClass}`}>
            <div className="container">
                <SectionTitle3 subTitle={'Nos compétences'} MainTitle={'Ce que nous proposons'}/>
                <div className="row align-items-center">
                    {Services.slice(6,9).map((service, sitem) => (
                        <div className="col-lg-4 col-md-6 col-12" key={sitem}>
                            <div className="wpo-service-item">
                                <div className="wpo-service-img">
                                    <img src={service.sImg} alt=""/>
                                </div>
                                <div className="wpo-service-text">
                                    <h2 style={{ color: '#FFFFFF' }}> {service.sTitle} </h2>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Competences;