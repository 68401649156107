import React from 'react';

const Mentions = () => {

    return (
        <div className="wpo-project-single-area section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-12">
                        <div className="wpo-project-single-wrap">
                            <div className="wpo-project-single-item">
                                <div className="wpo-project-single-item">
                                    <div className="wpo-project-single-title">
                                        <h3>Siège social</h3>
                                    </div>
                                    <p className="mb-0"><span style={{ fontWeight: 'bold' }}>Adresse :</span> Carib'n Services - 18 Rue Cour Campêche, 97200 Fort-de-France</p>
									<p className="mb-0"><span style={{ fontWeight: 'bold' }}>Téléphone :</span> 06 09 07 65 32</p>
									<p className="mb-0"><span style={{ fontWeight: 'bold' }}>Adresse email :</span> caribnservices@gmail.com</p>
									<p className="mb-0"><span style={{ fontWeight: 'bold' }}>SIRET :</span> 90871012200015</p>
									<p className="mb-0"><span style={{ fontWeight: 'bold' }}>SIREN :</span> 908710122</p>
									<p className="mb-0"><span style={{ fontWeight: 'bold' }}>RCS :</span> Fort-de-France B 908 710 122</p>
                                </div>

                                <div className="wpo-project-single-item">
                                    <div className="wpo-project-single-title">
                                        <h3>Directeur de la publication</h3>
                                    </div>
									<p className="mb-0">Le directeur de la publication du site est Monsieur Gérôme NOGRET, en qualité de Gérant.</p>
                                </div>      
                                
                                <div className="wpo-project-single-item">
                                    <div className="wpo-project-single-title">
                                        <h3>Conception et réalisation</h3>
                                    </div>
									<p className="mb-0">Le site web www.caribnservices.com est supervisé par la société VIRTUAVA, SAS au capital de 1 000 €, inscrite au RCS de Fort-de-France sous le numéro SIREN 922 540 034 et dont le SIRET est 922 540 034 00019, Code APE 6311Z.</p>
                                </div>

                                <div className="wpo-project-single-item">
                                    <div className="wpo-project-single-title">
                                        <h3>Hébergement</h3>
                                    </div>
									<p className="mb-0">Le site web www.caribnservices.com est hébergé par Vercel, www.vercel.com, dont le siège social est situé à San Francisco, aux États-Unis.</p>
                                </div>

                                <div className="wpo-project-single-item">
                                    <div className="wpo-project-single-title">
                                        <h3>Crédits photos</h3>
                                    </div>
									<p className="mb-0">Freepik.</p>
                                </div>

                                <div className="wpo-project-single-item">
                                    <div className="wpo-project-single-title">
                                        <h3>Propriété Intellectuelle</h3>
                                    </div>
									<p className="mb-0">Le contenu de ce site, incluant, sans s'y limiter, les images, textes, graphiques et logos, est la propriété de Carib'n Services, sauf mention contraire. Toute reproduction, distribution, modification ou utilisation de ces éléments, sans autorisation préalable écrite de Carib'n Services, est interdite.</p>
                                </div>

                                <div className="wpo-project-single-item">
                                    <div className="wpo-project-single-title">
                                        <h3>Limitation de Responsabilité</h3>
                                    </div>
									<p className="mb-0">Les informations contenues sur ce site sont aussi précises que possible et le site est périodiquement mis à jour, mais peut toutefois contenir des inexactitudes, des omissions ou des lacunes. Si vous constatez une lacune, erreur ou ce qui semble être un dysfonctionnement, merci de bien vouloir le signaler par email, en décrivant le problème de la manière la plus précise possible.</p>
                                </div>

                                <div className="wpo-project-single-item">
                                    <div className="wpo-project-single-title">
                                        <h3>Protection des Données Personnelles</h3>
                                    </div>
									<p className="mb-0">Conformément à la réglementation en vigueur, les informations collectées via les formulaires présents sur le site sont destinées exclusivement à Carib'n Services et ne seront pas divulguées à des tiers. Pour toute information ou exercice de vos droits Informatique et Libertés sur les traitements de données personnelles gérés par Carib'n Services, vous pouvez contacter son représentant légal.</p>
                                </div>

                                <div className="wpo-project-single-item">
                                    <div className="wpo-project-single-title">
                                        <h3>Modification des Mentions Légales</h3>
                                    </div>
									<p className="mb-0">Carib'n Services se réserve le droit de modifier ces mentions à tout moment. L'utilisateur est invité à les consulter régulièrement.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Mentions;
