import React, {Fragment} from 'react';
import Navbar2 from '../../components/Navbar2'
import Hero from '../../components/hero';
import About from '../../components/about';
import ServiceSection3 from '../../components/Competences';
import Footer from '../../components/footer';
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo-blanc.png'
import abimg from '../../images/about.jpg'

const HomePage =() => {
    return(
        <Fragment>
            <Navbar2 Logo={Logo} hclass={'wpo-header-style-3'}/>
            <Hero/>
            <About abClass={'wpo-about-area-s5'} abimg={abimg}/>
            <ServiceSection3 ptClass={'pt-0'}/>
            <Footer ftClass={'wpo-site-footer-s2'}/>
            <Scrollbar/>         
        </Fragment>
    )
};
export default HomePage;