import React from 'react'
import {Link} from 'react-router-dom'
import erimg from '../../images/error-404.svg'


const Error = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(
        <section className="error-404-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="content clearfix">
                            <div className="error">
                                <img src={erimg} alt=""/>
                            </div>
                            <div className="error-message">
                                <h3>Oops, cette page est introuvable...</h3>
                                <p>Il est possible que cette page ait été effacée ou n'ait jamais existée.<br/>Pour continuer votre navigation, veuillez de cliquer sur le bouton ci-dessous.</p>
                                <Link onClick={ClickHandler} to="/" className="theme-btn">Retour à la page d'accueil</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Error;