import React, { Component } from 'react'
import Swal from "sweetalert2";
import axios from 'axios';
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

class ContactForm extends Component {

    state = {
        name: '',
        email: '',
        lastname: '',
        phone: '',
        message: '',
        error: {}
    }


    alertContent = (content) => {
        const { title, text, icon } = content
        MySwal.fire({
            title,
            text,
            icon,
            timer: 7000,
            timerProgressBar: true,
            showConfirmButton: false,
        });
    };

    changeHandler = (e) => {
        const error = this.state.error;
        error[e.target.name] = ''

        this.setState({
            [e.target.name]: e.target.value,
            error
        })
    }

    subimtHandler = async (e) => {
        e.preventDefault();

        let isEmpty = true
        const { name,
            email,
            message,
            lastname,
            phone,
            error } = this.state;

        if (name === '') {
            error.name = "Merci de renseigner votre prénom";
            isEmpty = false
        }
        if (email === '') {
            error.email = "Merci de renseigner votre adresse email";
            isEmpty = false
        }
        if (lastname === '') {
            error.lastname = "Merci de renseigner votre nom";
            isEmpty = false
        }
        if (phone === '') {
            error.phone = "Merci de renseigner votre telephone";
            isEmpty = false
        }
        if (message === '') {
            error.message = "Merci de renseigner votre message";
            isEmpty = false
        }


        if (!isEmpty) {
            this.setState({
                error
            })
        } else {
            try {
                const url = 'https://api.caribnservices.com/sendMail'
                const data = {
                    from: 'contact-relay@caribnservices.com',
                    to: 'caribnservices@gmail.com',
                    subject: `Formulaire de contact - site web`,

                    content: `
                    <p>Vous avez été contactés par : ${name} ${lastname}</p>
                    <p>Numéro de téléphone : ${phone.replace(/\D/g, '')}</p>
                    <p>Adresse email : ${email}</p>
                    <p>Message : ${message}</p>
                    `
                }
                const response = await axios.post(url, { data });
                const contentData = {
                    title: "Félicitations!",
                    text: "Nous avons bien reçu votre message. Nous vous répondrons dans un délai maximal de 48h. Merci de votre compréhension.",
                    icon: "success"
                }
                this.alertContent(contentData);

            } catch (error) {
                const contentData = {
                    title: "Erreur",
                    text: "Il semble y avoir un incident lors de l'envoi de votre message. Merci de bien vouloir réessayer.",
                    icon: "error"
                }
                this.alertContent(contentData)

            }
        }

        if (error.name === '' && error.email === '' && error.message === '' && error.lastname === '' && error.phone === '') {
            this.setState({
                name: '',
                email: '',
                phone: '',
                lastname: '',
                message: '',
                error: {}
            })
        }
    }

    render() {
        const { name,
            email,
            message,
            lastname,
            phone,
            error } = this.state;

        return (
            <form onSubmit={this.subimtHandler} className="form">
                <div className="row justify-content-center">

                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input value={name} onChange={this.changeHandler} type="text" name="name" placeholder="Prénom" />
                            <p>{error.name ? error.name : ''}</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input value={lastname} onChange={this.changeHandler} type="text" name="lastname" placeholder="Nom" />
                            <p>{error.lastname ? error.lastname : ''}</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input onChange={this.changeHandler} value={email} type="email" name="email" placeholder="Adresse email" />
                            <p>{error.email ? error.email : ''}</p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input onChange={this.changeHandler} value={phone} type="phone" name="phone" placeholder="Téléphone" />
                            <p>{error.phone ? error.phone : ''}</p>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="form-field">
                            <textarea onChange={this.changeHandler} value={message} name="message" placeholder="Tapez votre message..."></textarea>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="form-submit">
                            <button type="submit" className="theme-btn">Envoyer</button>
                        </div>
                    </div>

                </div>
            </form>
        )
    }

}
export default ContactForm;